import React, { Component } from 'react';

export default class extends Component {
  state = {
    gigs: [],
    gig: {
      id: '',
      name: '',
      type: 'default',
      dev_share: '--',
      amount: 0,
      total: 0,
      delete: false
    },
    increment: 0,
    amt_hold: null,
    copied: false
  }

  handleChange = e => {
    this.setState({ gig: {
      ...this.state.gig,
      [e.target.name]: e.target.value
    } })
  }

  gigTypeChange = e => {
    const val = e.target.value
    const gig = gig_types.find(x => x.type === val)
    const { type } = gig;
    let amount;
    let amt_hold;

    if(val === 'Custom' || val === 'Bundle') {
      amount = window.prompt('Enter Gig Amount:')
      amount = amount.split(',').join('')
      amt_hold = parseInt(amount).toLocaleString()
      console.log(amt_hold)
    } else {
      amount = gig.amount
    }

    this.setState({ 
      gig: {
        ...this.state.gig,
        type, amount,
        dev_share: '80%',
      },
      amt_hold
    })
  }

  addGig = e => {
    e.preventDefault()

    const { amount, dev_share, name } = this.state.gig
    const share = dev_share === '40%' ? 0.4 : dev_share === '50%' ? 0.5 : 0.8
    const total = amount * share

    this.setState( prevState => ({
      gigs: [
        ...prevState.gigs, 
        { 
          ...prevState.gig,
          amount, 
          total, 
          id: `${this.state.increment + 1}-${name}` 
        }
      ],
      gig: {
        name: '',
        type: 'default',
        dev_share: '--',
        amount: 0,
        total: 0
      },
      increment: this.state.increment + 1,
      amt_hold: null
    }) )
  }

  showDelete = id => {
    this.setState({ gigs: this.state.gigs.map(gig => {
      if(gig.id === id) {
        gig.delete = true
      } else {
        gig.delete = false
      }
      return gig
    }) })
  }

  hideDelete = id => {
    this.setState({ gigs: this.state.gigs.map(gig => {
      if(gig.id === id) {
        gig.delete = false
      }
      return gig
    }) })
  }

  deleteRow = id => {
    this.setState({ gigs: this.state.gigs.filter(gig => gig.id !== id) })
  }

  copyTable = () => {
    const elTable = document.querySelector('table');
    
    let range;
    let sel;
    
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
    
      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }
    
      document.execCommand('copy');
    }
    
    sel.removeAllRanges();
    
    this.setState({ copied: true })

    setTimeout(() => this.setState({ copied: false }), 3000)
  }

  render() {
    const { gigs, gig, copied } = this.state;
    const { name } = gig;

    const gig_type_select = { 
      textIndent: '8px', 
      height: '38px', 
      width: 'calc(70% - 5px)', 
      background: gig.name === '' ? 'gray' : '#50d366', 
      border: '2px solid black',
      fontSize: '1.1rem',
      color: 'white',
      textShadow: '1px 1px rgba(0,0,0,0.5), 1px 0px rgba(0,0,0,1)'
    }

    const dev_share_select = { 
      textIndent: '8px',
      height: '38px',
      width: '30%',
      border: '2px solid black',
      background: gig.type === 'default' ? 'gray' : '#50d366',
      fontSize: '1.1rem',
      color: 'white',
      textShadow: '1px 1px rgba(0,0,0,0.5), 1px 0px rgba(0,0,0,1)'
    }

    const toggle_copy_text = {
      opacity: copied ? 1 : 0,
      transition: 'opacity 1s ease-out 100ms',
      fontSize: '1.3rem',
      color: '#50d366',
      marginTop: '20px',
      fontWeight: 'bold',
      textShadow: '1px 1px 2px black, 1px 0px 2px black'
    }

    return (
      <div style = { page }>
        <img style = { logo } src = { require('./assets/logo.svg') } alt="logo" />
        <div style = { form }>
          <div>
            <input 
              type="text"
              placeholder="Site Name"
              name="name"
              value = { name }
              onChange = { this.handleChange }
              style = { input }
            />
            <div style = { selects }>
              <select 
                onChange = { this.gigTypeChange }
                value = { this.state.gig.type } 
                style = { gig_type_select }
                disabled = { gig.name === '' }
              >
                <option disabled value="default">Gig Type</option>
                { gig_types.sort((a, b) => {
                  let typeA = a.type.toUpperCase();
                  let typeB = b.type.toUpperCase();
                  return (typeA < typeB) ? -1 : (typeA > typeB) ? 1 : 0;
                }).map(type => (
                  <option 
                    value = { type.type }
                    key = { type.type }
                  >
                    { this.state.amt_hold ? `${type.type} - $${ this.state.amt_hold }` : type.type }
                  </option>
                )) }
              </select>
              <select 
                onChange = { this.handleChange } 
                name="dev_share"
                value = { this.state.gig.dev_share }
                style = { dev_share_select }
                disabled = { gig.type === 'default' }
              >
                <option disabled value="--">--</option>
                <option value="40%">40%</option>
                <option value="50%">50%</option>
                <option value="80%">80%</option>
              </select>
            </div>
          </div>
          <button onClick = { this.addGig } style = { button }>
            Add Gig
          </button>
        </div>
        { gigs.length ? 
          <>
            <table ref="table">
              <thead>
                <th style = {{ ...td, fontWeight: 'bold' }}>Site</th>
                <th style = {{ ...td, fontWeight: 'bold' }}>Gig Type</th>
                <th style = {{ ...td, fontWeight: 'bold' }}>Dev Split</th>
                <th style = {{ ...td, fontWeight: 'bold' }}>Dev Share</th>
              </thead>
              <tbody>
                { gigs.map((g, i) => (
                  <tr
                    onMouseEnter = { () => this.showDelete(g.id) }
                    onMouseLeave = { () => this.hideDelete(g.id) }
                    key = { i }
                  >
                    <td style = { td }>{ g.name }</td>
                    <td style = { td }>{ g.type }</td>
                    <td style = { td }>${ parseInt(g.amount).toLocaleString() } - { g.dev_share }</td>
                    <td style = { total }>${ g.total.toLocaleString() }</td>
                    <td 
                      style = {{ 
                        ...td, 
                        display: g.delete ? 'block' : 'none',
                        position: 'absolute',
                        color: 'red',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                      onClick = { () => this.deleteRow(g.id) }
                    >
                      Delete
                    </td>
                  </tr>
                )) }
                <tr>
                  <td />
                  <td />
                  <td />
                  <td style = { td }>Total: <span style ={{ fontWeight: 'bold' }}>${ gigs.reduce((a, b) => a + b.total, 0).toLocaleString().includes('.') && gigs.reduce((a, b) => a + b.total, 0).toLocaleString().indexOf('.') === gigs.reduce((a, b) => a + b.total, 0).toLocaleString().length - 2 ? `${gigs.reduce((a, b) => a + b.total, 0).toLocaleString()}0` : gigs.reduce((a, b) => a + b.total, 0).toLocaleString() }</span></td>
                </tr>
              </tbody>
            </table>
            <button onClick = { () => this.copyTable() } style = {{ ...button, marginTop: '20px', background: '#50d366' }}>
              Copy to Clipboard
            </button>
            <div style = { toggle_copy_text }>
              Table Copied!
            </div>
          </>
        :
          null
        }
      </div>
    );
  }
}

// styles
const page = {
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const logo = {
  width: '300px',
  margin: '25px'
}

const form = { 
  display: 'flex', 
  flexDirection: 'column', 
  marginBottom: '25px', 
  width: '300px',
}

const input = {
  background: '#50d366',
  width: '100%', 
  padding: '8px',
  borderRadius: '4px',
  border: '2px solid black',
  fontSize: '1.1rem',
  marginBottom: '5px',
  textIndent: '3px',
  color: 'white',
  textShadow: '1px 1px rgba(0,0,0,1), 1px 0px rgba(0,0,0,1)'
}

const selects = { 
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'space-between',
}

const button = { 
  padding: '8px', 
  background: '#1d96d4',
  borderRadius: '4px',
  border: '2px solid black',
  color: 'white',
  fontSize: '1.1rem',
  textShadow: '1px 1px rgba(0,0,0,1), 1px 0px rgba(0,0,0,1)'
}

const td = {
  border: '1px solid black',
  padding: '8px'
}

const total = { 
  ...td, 
  fontWeight: 'bold', 
  textAlign: 'right' 
}

const gig_types = [
  { 
    type: 'App Check',
    amount: 100
  },
  { 
    type: 'Essentials - Old',
    amount: 350
  },
  { 
    type: 'Essentials',
    amount: 325
  },
  { 
    type: 'Entrepreneur - Old',
    amount: 500
  },
  { 
    type: 'Entrepreneur',
    amount: 475
  },
  { 
    type: 'Entrepreneur Plus - Old',
    amount: 800
  },
  { 
    type: 'Entrepreneur Plus',
    amount: 775
  },
  { 
    type: 'Lazy Loading',
    amount: 100
  },
  { 
    type: 'Image Optimization',
    amount: 200
  },
  {
    type: 'Image Audit',
    amount: 100
  },
  { 
    type: 'Minify CSS / JS',
    amount: 75
  },
  { 
    type: 'Preloading',
    amount: 100
  },
  { 
    type: 'App Analysis',
    amount: 250
  },
  { 
    type: 'Error Analysis/Fix - Old',
    amount: 100
  },
  { 
    type: 'Error Analysis/Fix',
    amount: 80
  },
  { 
    type: 'Deferred Video Loading',
    amount: 100
  },
  {
    type: 'Developer Analysis',
    amount: 150
  },
  { 
    type: 'Small Task',
    amount: 80
  },
  { 
    type: 'Hover 2nd Image',
    amount: 100
  },
  { 
    type: 'Collection Swatches',
    amount: 200
  },
  { 
    type: 'Calculators',
    amount: 100
  },
  { 
    type: 'Custom Checkbox',
    amount: 100
  },
  { 
    type: 'Automated Email Marketing',
    amount: 250
  },
  { 
    type: 'AMP Setup',
    amount: 100
  },
  { 
    type: 'App Setup',
    amount: 100
  },
  {
    type: 'Google PSI Audit',
    amount: 300
  },
  { 
    type: 'Conversion Analysis - Old',
    amount: 400
  },
  { 
    type: 'Conversion Analysis',
    amount: 500
  },
  { 
    type: 'A/B Testing',
    amount: 250
  },
  { 
    type: 'Floating Add to Cart',
    amount: 150
  },
  { 
    type: 'Custom Cross Sell',
    amount: 300
  },
  { 
    type: 'Infinite Scrolling',
    amount: 150
  },
  { 
    type: 'Shoppable Instagram',
    amount: 300
  },
  { 
    type: 'Product Tabs',
    amount: 150
  },
  { 
    type: 'Custom',
    amount: 0
  },
  {
    type: 'Bundle',
    amount: 0
  }
]